<template>
    <div>
        <div v-if="shouldRenderImage" class="c-navigation-menu-list__image-module">
            <ImageModule
                class="c-navigation-menu-list__featured-content"
                :backgroundImage="navImage.image"
                :destination="navImage.destination"
                @click="trackImageModuleClick"
            />
        </div>
        <ul class="c-navigation-menu-list">
            <template v-for="navItem in headerNav">
                <UrbnModalLauncher
                    v-if="!!navItem.children?.length"
                    :key="`${navItem.displayName}-heading`"
                    type="li"
                    :modalOptions="{
                        ...navMenuListModal,
                        title: navItem.displayName,
                        path: getModalPath(navItem),
                        props: {
                            headerNav: navItem.children,
                            navContent,
                            recursiveRenderIndex: recursiveRenderIndex + 1,
                        },
                    }"
                    class="c-navigation-menu-list__li"
                    @click="trackNavClick(navItem)"
                >
                    <span>{{ navItem.displayName }}</span>
                    <Badge
                        v-if="navItem.badge"
                        class="c-navigation-menu-list__badge"
                        :badgeText="navItem.badge.badgeText"
                        :icon="navItem.badge.icon"
                        :bkgColor="navItem.badge.bkgColor"
                        color="black"
                    />
                    <BaseIcon
                        class="c-navigation-menu-list__icon"
                        icon="global--chevron"
                        size="16px"
                        color="primary"
                        orientation="right"
                    />
                </UrbnModalLauncher>
                <li
                    v-else
                    :key="`${navItem.displayName}-link`"
                >
                    <BaseLink
                        :to="navItem.targetUrl || navItem.slug"
                        :target="navItem.target"
                        class="c-navigation-menu-list__a"
                        @click="trackNavClick(navItem)"
                    >
                        <span>{{ navItem.displayName }}</span>
                        <Badge
                            v-if="navItem.badge"
                            class="c-navigation-menu-list__badge"
                            :badgeText="navItem.badge.badgeText"
                            :icon="navItem.badge.icon"
                            :bkgColor="navItem.badge.bkgColor"
                            color="black"
                        />
                    </BaseLink>
                </li>
            </template>
        </ul>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useActions, useGetters } from '~coreModules/core/js/composables/vuexHelpers';

import { GLOBAL_EVENT } from '~coreModules/core/js/store';
import { GLOBAL_MODALS } from '~coreModules/modals/js/modal-constants';
import { NAVIGATION_CLICKED, CONTENTFUL_CONTENT_CLICKED } from '~coreModules/core/js/global-event-constants';

import UrbnModalLauncher from '~coreModules/modals/components/UrbnModalLauncher.vue';
import ImageModule from '~coreModules/contentful/components/ImageModule.vue';
import Badge from '~coreModules/core/components/ui/Badge.vue';

const props = defineProps({
    headerNav: {
        type: Array,
        default: () => [],
    },
    navContent: {
        type: Array,
        default: () => [],
    },
    recursiveRenderIndex: {
        type: Number,
        default: 0,
    },
    shouldRenderNavRootImage: {
        type: Boolean,
        default: true,
    },
});

const { trackGlobalEvent } = useActions({ trackGlobalEvent: GLOBAL_EVENT });
const { appBaseUrl } = useGetters(['appBaseUrl']);

const navMenuListModal = ref(GLOBAL_MODALS.navMenuList);

function getModalPath(navItem) {
    const baseUrl = appBaseUrl.value === '/' ? '' : appBaseUrl.value;

    return `${baseUrl}/navigation/l${navItem.nestLevel + 1}`;
}

function trackNavClick(navItem) {
    const navActionLevel = navItem.childSlugs?.length ? `top_nav_L${navItem.nestLevel}_expand` :
        `top_nav_L${navItem.nestLevel}_click`;

    const destination = !navItem.childSlugs?.length ? navItem.slug : null;

    trackGlobalEvent({
        type: NAVIGATION_CLICKED,
        data: {
            navAction: navActionLevel,
            navName: navItem.ancestorSlugs.concat(navItem.slug).join(' | '),
            navDestination: navItem.targetUrl || destination,
        },
    });
}

const headerNavSlug = computed(() => props.headerNav[0]?.parentSlug);
const navImage = computed(
    () => props.navContent.find(content => headerNavSlug.value === content.headingSlug));
const shouldRenderImage = computed(() => !!navImage.value?.image &&
        (props.shouldRenderNavRootImage || props.recursiveRenderIndex),
);

function trackImageModuleClick() {
    trackGlobalEvent({
        type: CONTENTFUL_CONTENT_CLICKED,
        data: {
            destination: navImage.value?.destination?.linkPath,
            contentModuleId: navImage?.value.title,
        },
    });
}

</script>

<style lang="scss">
.c-navigation-menu-list {
    background-color: $nu-white;

    &__a,
    &__li {
        outline-offset: -4px;
    }

    &__a {
        width: 100%;
        padding: $nu-spacer-2;
        border-bottom: 1px solid $nu-gray--light;
        color: $nu-black;
    }

    &__li {
        cursor: pointer;
        padding: $nu-spacer-2;
        width: 100%;
        border-bottom: 1px solid $nu-gray--light;

        display: inline-flex;
        border-bottom: 1px solid $nu-gray--light;
        justify-content: space-between;
        align-items: center;
    }

    &__image-module {
        padding: $nu-spacer-2;
        background: $nu-white;
        border-bottom: 1px solid $nu-gray--light;
    }

    &__featured-content {
        aspect-ratio: 2/1;
        background-color: $placeholder-background;
    }

    &__badge {
        margin-left: $nu-spacer-2;
    }

    &__icon {
        margin-left: auto;
    }
}
</style>
