<i18n>
[
    "footer__about--title",
]
</i18n>

<template>
    <CoreNavigationMenu>
        <div class="c-navigation-menu__search-container">
            <SearchBar class="c-navigation-menu__search" />
        </div>

        <NavigationMenuList
            :headerNav="headerNav"
            :navContent="navigationContent"
            class="u-spacer--3"
        />

        <div class="c-navigation-menu__anonymous-links u-spacer--6">
            <p class="o-text--label u-spacer--1pt5 u-text--gray c-navigation-menu__anonymous-links-header">
                {{ $t('footer__about--title') }}
            </p>
            <NavigationLinksMenu :navigationLinks="anonymousTopLinks" />
        </div>
    </CoreNavigationMenu>
</template>

<script setup>
import { useGetters, useState } from '~coreModules/core/js/composables/vuexHelpers';

import { NAVIGATION_MODULE_NAME } from '~modules/nav/js/nav-store';

import CoreNavigationMenu from '~coreModules/nav/components/CoreNavigationMenu.vue';
import NavigationMenuList from '~coreModules/nav/components/NavigationMenuList.vue';
import NavigationLinksMenu from '~coreModules/nav/components/NavigationLinksMenu.vue';

import SearchBar from '~modules/search/components/SearchBar.vue';

const { headerNav, anonymousTopLinks } = useGetters(
    NAVIGATION_MODULE_NAME,
    [
        'headerNav',
        'anonymousTopLinks',
        'featuredNavigationContent',
    ],
);

const { navigationContent } = useState(NAVIGATION_MODULE_NAME, {
    navigationContent: state => state.navigationContent,
});

</script>

<style lang="scss">
.c-navigation-menu {
    &__search-container {
        display: block;
        background: $nu-white;
        padding: $nu-spacer-2;
        @include breakpoint(large) {
            display: none;
        }
    }

    &__search {
        max-width: 100%;
    }

    &__contentful-block {
        padding: $nu-spacer-2;
        background: $nu-white;
        border-bottom: 1px solid $nu-gray--light;
    }

    &__anonymous-links {
        display: block;
        @include breakpoint(large) {
            display: none;
        }
    }

    &__anonymous-links-header {
        padding-left: $nu-spacer-2;
    }
}
</style>
